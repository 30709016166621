import { initClientSideSentry } from "$lib/client/sentry";
import * as Sentry from "@sentry/sveltekit";
import { logger } from "./lib/logger";

initClientSideSentry();

export async function handleError(context) {
	const { error, event } = context;

	Sentry.captureException(error, {
		extra: {
			event,
			context: {
				accountId: null,
			},
		},
	});
}
