import { logger } from "$lib/logger";
import * as Sentry from "@sentry/sveltekit";
import { replayIntegration } from "@sentry/sveltekit";
export function initClientSideSentry() {
	Sentry.init({
		dsn: PUBLIC_SENTRY_DSN,
		tracesSampleRate: 1.0,
		// this tells you what sentry is doing, but it is overly verbose
		debug: false,

		// This sets the sample rate to be 10%. You may want this to be 100% while
		// in development and sample at a lower rate in production
		replaysSessionSampleRate: PUBLIC_SENTRY_ENVIRONMENT === "local" ? 0.1 : 1.0,

		// If the entire session is not sampled, use the below sample rate to sample
		// sessions when an error occurs.
		replaysOnErrorSampleRate: 1.0,

		// If you don't want to use Session Replay, just remove the line below:
		integrations: [
			replayIntegration({
				maskAllText: true,
				blockAllMedia: true,
			}),
		],
		environment: PUBLIC_SENTRY_ENVIRONMENT,
		enabled: true,
		beforeSend: (event) => {
			const env = PUBLIC_SENTRY_ENVIRONMENT;
			if (env === "local") {
				const message =
					event.exception?.values?.[0]?.value ?? "Unknown client error";
				const stacktrace = event.exception?.values?.[0]?.stacktrace?.frames;
				logger.error("[Sentry/Local]", {
					message,
					stacktrace,
				});

				// Drop the event so it doesn't get sent
				return null;
			}
			return event;
		},
	});
}
