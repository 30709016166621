import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99'),
	() => import('./nodes/100'),
	() => import('./nodes/101'),
	() => import('./nodes/102'),
	() => import('./nodes/103'),
	() => import('./nodes/104'),
	() => import('./nodes/105'),
	() => import('./nodes/106'),
	() => import('./nodes/107'),
	() => import('./nodes/108'),
	() => import('./nodes/109'),
	() => import('./nodes/110'),
	() => import('./nodes/111'),
	() => import('./nodes/112')
];

export const server_loads = [0,2,32,33,30,3,7,8,10,11,5,6,13,18,23,20,21,25,26];

export const dictionary = {
		"/(public)": [~105,[31]],
		"/(private)/create-account": [~99,[2,29]],
		"/(public)/(premium)/guides": [106,[31,32,33],[,,34]],
		"/(public)/(premium)/guides/[slug]": [~107,[31,32,33,35],[,,34]],
		"/(private)/home": [100,[2]],
		"/(public)/login": [110,[31,38]],
		"/(private)/profile": [101,[2,30]],
		"/(private)/profile/accounts": [102,[2,30]],
		"/(private)/profile/invites": [103,[2,30]],
		"/(private)/profile/invites/[inviteId]": [~104,[2,30]],
		"/(public)/sentry/client": [111,[31]],
		"/(public)/sentry/server": [~112,[31]],
		"/(public)/(premium)/tutorials": [~108,[31,32,36]],
		"/(public)/(premium)/tutorials/[slug]": [~109,[31,32,36,37]],
		"/(private)/[accountId]": [~39,[3],[4]],
		"/(private)/[accountId]/activity": [75,[3,22],[4]],
		"/(private)/[accountId]/(cloudflare)/(synced)/activity/devices": [45,[3,7,8,9,10],[4]],
		"/(private)/[accountId]/(cloudflare)/(synced)/activity/dns": [~46,[3,7,8,9,11],[4]],
		"/(private)/[accountId]/(cloudflare)/(synced)/activity/dns/recent": [47,[3,7,8,9,11],[4]],
		"/(private)/[accountId]/activity/history": [76,[3,22],[4]],
		"/(private)/[accountId]/(checkout)/checkout": [40,[3,5,6],[4]],
		"/(private)/[accountId]/(checkout)/checkout/stripe": [~41,[3,5,6],[4]],
		"/(private)/[accountId]/(cloudflare)/(synced)/content-policy": [~48,[3,7,8,12],[4]],
		"/(private)/[accountId]/(cloudflare)/(synced)/content-policy/connect": [~49,[3,7,8,12,13],[4]],
		"/(private)/[accountId]/(cloudflare)/(synced)/content-policy/connect/android": [~50,[3,7,8,12,13],[4]],
		"/(private)/[accountId]/(cloudflare)/(synced)/content-policy/connect/android/app": [51,[3,7,8,12,13],[4]],
		"/(private)/[accountId]/(cloudflare)/(synced)/content-policy/connect/android/certificate": [52,[3,7,8,12,13,14],[4]],
		"/(private)/[accountId]/(cloudflare)/(synced)/content-policy/connect/app/prevent-bypass": [53,[3,7,8,12,13],[4]],
		"/(private)/[accountId]/(cloudflare)/(synced)/content-policy/connect/ios": [~54,[3,7,8,12,13],[4]],
		"/(private)/[accountId]/(cloudflare)/(synced)/content-policy/connect/ios/app": [55,[3,7,8,12,13],[4]],
		"/(private)/[accountId]/(cloudflare)/(synced)/content-policy/connect/ios/certificate": [56,[3,7,8,12,13,15],[4]],
		"/(private)/[accountId]/(cloudflare)/(synced)/content-policy/connect/mac": [~57,[3,7,8,12,13],[4]],
		"/(private)/[accountId]/(cloudflare)/(synced)/content-policy/connect/mac/app": [58,[3,7,8,12,13],[4]],
		"/(private)/[accountId]/(cloudflare)/(synced)/content-policy/connect/mac/certificate": [59,[3,7,8,12,13,16],[4]],
		"/(private)/[accountId]/(cloudflare)/(synced)/content-policy/connect/router": [~60,[3,7,8,12,13],[4]],
		"/(private)/[accountId]/(cloudflare)/(synced)/content-policy/connect/windows": [~61,[3,7,8,12,13],[4]],
		"/(private)/[accountId]/(cloudflare)/(synced)/content-policy/connect/windows/app": [62,[3,7,8,12,13],[4]],
		"/(private)/[accountId]/(cloudflare)/(synced)/content-policy/connect/windows/certificate": [63,[3,7,8,12,13,17],[4]],
		"/(private)/[accountId]/(cloudflare)/(synced)/content-policy/debug": [64,[3,7,8,12],[4]],
		"/(private)/[accountId]/(cloudflare)/(synced)/content-policy/rules/[ruleId]": [~65,[3,7,8,12,18],[4]],
		"/(private)/[accountId]/(cloudflare)/(synced)/content-policy/rules/[ruleId]/history": [~66,[3,7,8,12,18],[4]],
		"/(private)/[accountId]/(checkout)/select-plan": [42,[3,5],[4]],
		"/(private)/[accountId]/settings": [77,[3],[4]],
		"/(private)/[accountId]/settings/account": [78,[3],[4]],
		"/(private)/[accountId]/settings/billing": [~79,[3,23],[4]],
		"/(private)/[accountId]/(cloudflare)/settings/cloudflare/(synced)": [~67,[3,7,19,20],[4]],
		"/(private)/[accountId]/(cloudflare)/settings/cloudflare/(synced)/agent": [~68,[3,7,19,20],[4]],
		"/(private)/[accountId]/(cloudflare)/settings/cloudflare/(synced)/certificates": [69,[3,7,19,20,21],[4]],
		"/(private)/[accountId]/settings/cloudflare/connect": [~80,[3,24],[4]],
		"/(private)/[accountId]/(cloudflare)/settings/cloudflare/(synced)/debug": [~70,[3,7,19,20],[4]],
		"/(private)/[accountId]/(cloudflare)/settings/cloudflare/(synced)/networks": [~71,[3,7,19,20],[4]],
		"/(private)/[accountId]/(cloudflare)/settings/cloudflare/(synced)/networks/location": [~72,[3,7,19,20],[4]],
		"/(private)/[accountId]/(cloudflare)/settings/cloudflare/sync": [~74,[3,7,19],[4]],
		"/(private)/[accountId]/(cloudflare)/settings/cloudflare/(synced)/traffic": [~73,[3,7,19,20],[4]],
		"/(private)/[accountId]/settings/members": [~81,[3],[4]],
		"/(private)/[accountId]/settings/profile": [~82,[3,25],[4]],
		"/(private)/[accountId]/setup": [~83,[3,26],[4]],
		"/(private)/[accountId]/setup/ios": [~84,[3,26],[4]],
		"/(private)/[accountId]/setup/ios/prevent-bypass": [85,[3,26],[4]],
		"/(private)/[accountId]/setup/ios/prevent-bypass/supervised": [86,[3,26],[4]],
		"/(private)/[accountId]/setup/mac/prevent-bypass": [87,[3,26],[4]],
		"/(private)/[accountId]/(checkout)/success": [43,[3,5],[4]],
		"/(private)/[accountId]/(checkout)/success/stripe": [~44,[3,5],[4]],
		"/(private)/[accountId]/tools": [88,[3,27],[4]],
		"/(private)/[accountId]/tools/ios": [89,[3,27],[4]],
		"/(private)/[accountId]/tools/ios/app-management": [~90,[3,27,28],[4]],
		"/(private)/[accountId]/tools/ios/app-management/allowlist": [~91,[3,27,28],[4]],
		"/(private)/[accountId]/tools/ios/app-management/blocklist": [~92,[3,27,28],[4]],
		"/(private)/[accountId]/tools/ios/config-presets": [93,[3,27],[4]],
		"/(private)/[accountId]/tools/mac": [94,[3,27],[4]],
		"/(private)/[accountId]/tools/mac/config-presets": [95,[3,27],[4]],
		"/(private)/[accountId]/tools/mac/warp-enforcer": [96,[3,27],[4]],
		"/(private)/[accountId]/tools/windows": [97,[3,27],[4]],
		"/(private)/[accountId]/tools/windows/content-policy-enforcer": [98,[3,27],[4]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';